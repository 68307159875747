<template>
  <v-container fluid>
<!--    <Search v-model="filter" />-->
    <v-row >
      <v-col cols="10" class="mx-auto">
        <v-row>
          <v-col cols="3" v-for="product in products" :key="product.id" class="py-5">
            <product-card-slide :product="product" />
          </v-col>
        </v-row>
        <v-pagination
          v-if="meta.total && meta.last_page > 1"
          v-model="page"
          :length="meta.last_page"
          circle
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import ProductCard from '@/components/ProductCard';
  import ProductCardSlide from '@/components/products/ProductCardSlide';
  import Search from '@/components/Search';
  import {mapState} from 'vuex';
  import GlobalLoader from "@/components/loaders/GlobalLoader";

  export default {
    name: "SearchScreen",
    data: function () {
      return {
        page: 1,
      }
    },
    computed: {
      ...mapState('products', {
        products: state => state.list,
        meta: state => state.meta
      }),
      ...mapState('search', {
        searchQuery: state => state.query
      })
    },
    watch: {
      page() {
        this.fetchProducts()
      },
      searchQuery() {
        this.fetchProducts()
      }
    },
    mounted () {
      this.fetchProducts()
    },
    methods: {
      fetchProducts() {
        this.$store.dispatch('products/fetchProducts', {
          filter: this.searchQuery, page: this.page
        })
      }
    },

    components: {ProductCardSlide, GlobalLoader, Search}
  }
</script>

<style scoped>

</style>
